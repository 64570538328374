<template>
<div id="content">
  <div id="content-text">
    <div v-for="item,index in list" :key="index">
      <div class="content-title">
        <h1>{{ item.title }}</h1>
        <span>{{ item.date }}</span>
      </div>
      <div class="content-content" v-for="content,index in item.content" :key="index">
        <h2>{{ content.subTitle }}</h2>
        <p v-for="conTxt,index in content.txt" :key="index">
          {{ conTxt }}
        </p>
        <div class="content-pic">
          <img v-for="pic,index in content.pic" :key="index" :src="pic"/>
        </div>
        <i>{{ content.picTxt }}</i>
      </div>
    </div>
    <div></div>
  </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      list: []
    }
  },
  created() {
    let obj = this.$store.state.bambooList[0].treeCont[0];
    this.list.push({
      title: obj.title,
      date: obj.date,
      content: obj.content
    })
    // this.$route.name = obj.title;
  },
}
</script>

<style>
div#content {
  position: relative;
  margin: 0 auto;
  /* margin-bottom: 5rem; */
}
div#content  div.content-title {
  display: block;
  border-bottom: 1px solid #999999;
  padding-bottom: 5px;
}
div#content  div.content-title::after {
  content: "";
	clear: both;
	content: '';
	display: block;
	width: 0;
	height: 0;
	visibility: hidden;
}
div#content div.content-title > h1 {
  width: 100%;
  font-size: 1.5rem;
  float: left;
  font-weight: bold;
  line-height: 2.8125rem;
  margin-bottom: 0.625rem;
  color: #323232;
  display: block;
}
div#content div.content-title > span {
  display: block;
  float: left;
  color: #999999;
  font-style: normal;
  font-size: 0.875rem;
}  
div#content div.content-content {
  margin: 0 auto;
  color: #666;
  display: block;  
}
div#content div.content-content > h2 {
line-height: 150%;
font-size: 20px;
font-weight: bolder;
text-indent: 2em;
margin: 20px 0 10px 0;
}
div#content div.content-content > p {
  font-size: 16px;
  line-height: 240%;text-indent: 1.75em;
}
div#content div.content-content > div.content-pic {
  width: 80%;
  display: flex;
  -ms-flex-align: center;
  -ms-flex-item-align: center;
  margin: 0 auto;
  padding: 5px;
  text-align: center;
  justify-content: center;
  align-items: center;
}
div#content div.content-content > div.content-pic > img {
  width: 55%;
  display: flex;
  margin: 0 auto;
  float: left;
  margin: 10px;
  -ms-flex-align: center;
  -ms-flex-item-align: center;
  text-align: center;
  }
div#content div.content-content > i {
  text-align: center;
  display: block;
  margin: 0 auto;
  margin: 5px;
  color: #222;
  font-size: 12px;
}
</style>